@import "~bootstrap/scss/bootstrap";
@import "../../node_modules/@fullcalendar/common/main.min.css";
@import "../../node_modules/@fullcalendar/daygrid/main.min.css";
@import "../../node_modules/@fullcalendar/list/main.min.css";
@import "../../node_modules/@fullcalendar/timegrid/main.min.css";
@import "../../node_modules/bootstrap-icons/font/bootstrap-icons.css";

@font-face {
    font-family: 'FA Regular';
    src: url("../../node_modules/@fortawesome/fontawesome-free/webfonts/fa-regular-400.woff2") format("woff2");
}

body {
    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background: #364261;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: ligthen(#364261, 5%);
    }
}

.cursor-pointer {
    transition: all .2s ease-in-out;
    &:hover {
        cursor: pointer;
    }
}

.panel .text-warning {
    color: #ffca2c!important;
}

.searchdoc_logo {
    height: 30px;
}

.select2-parent-container {
    .select2-container--default {
        .select2-selection--single,.select2-selection--multiple {
            padding: 0.375rem 0.75rem!important;
            font-size: 0.875rem!important;
            font-weight: 400!important;
            line-height: 1.5!important;
            color: #4d5875!important;
            background-color: #fff!important;
            background-clip: padding-box!important;
            border: 1px solid #e1e5ef!important;
            border-radius: 3px!important;
            border-bottom-left-radius: 0!important;
            border-bottom-right-radius: 0!important;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out!important;
            .select2-selection__rendered {
                padding-left: 0!important;
            }
            .select2-selection__arrow b {
                margin-top: 3px!important;
            }
        }
        .select2-selection--single {
            height: 40px!important;
        }
        .select2-selection--multiple {
            height: auto!important;
        }
    }
}
.select2-container.select2-container--open {
    .select2-dropdown, input.select2-search__field {
        border: 1px solid #e1e5ef!important;
    }
}


.select2-container--default .select2-selection--multiple {
    padding-bottom: 0!important;
    border: 1px solid #aaa!important;
}

.visually-hidden {
    visibility: hidden!important;
}

.swal2-styled.swal2-confirm {
    background-color: #03418f!important;
}

.cke_dialog {
    -webkit-box-shadow: 0px 0px 10px -1px #000000; 
    box-shadow: 0px 0px 10px -1px #000000;
}

.module-infos .service {
    min-width: 220px;
}

#information_search {
    border-right: none!important;
}

#clear-search {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: white;
    border-top: 1px solid #dee2e6 !important;
    border-right: 1px solid #dee2e6 !important;
    border-bottom: 1px solid #dee2e6 !important;
    border-left: none;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 3px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:hover, &:active, &:visited, &:focus {
        color: rgb(115, 127, 158)!important;
    }
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 3px;
    margin-top: 15px;
    & > span {
        min-height: unset!important;
        border: 1px solid #cdd7ef;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 38px;
        font-weight: 500;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        border-radius: 4px;
        background-color: #ecf0fa;
        margin-right: 4px;
        & > a {
            padding: 0.5rem 0.75rem;
            line-height: 1.25;
        }
        &.current {
            padding: 0.5rem 0.75rem;
            line-height: 1.25;
            z-index: 1;
            color: #fff;
            background-color: #03418f;
            border-color: #03418f;
        }
        &:hover {
            background-color: #03418f !important;
            border: 1px solid #03418f !important;
            cursor: pointer;
            a {
                color: white;
            }
        }
    }
}

.tab-card {
    border: none!important;
    border-radius: 0!important;
}

label.required {
    position: relative;
    &::after {
        content: " *";
        color: red;
    }
}

.tel-number {
    width: 100%!important;
}

.important-field {
    padding: 5px 10px;
    background-color: #e5e5e5;
    border-radius: 2px;
}

#ajout-numero, #generate-courrier {
    width: fit-content!important;
    &:hover {
        cursor: pointer;
    }
}

.contactTel {
    display: none!important;
    &.active {
        display: block!important;
    }
}

ul.country-list {
    z-index: 2!important;
}

.collection-remove-btn {
    position: absolute;
    top: -16px;
    right: -10px;
    font-size: 1.1em;
}

.fichier-collection-child , .contact-collection-child {
    position: relative;
}

.form-label {
    color: #4d5875d1!important;
    font-weight: 600!important;
}

// .modal {
//     z-index: 11002!important;
// }

// .select2-container {
//     z-index: 11001!important;
// }

// .main-content .main-header {
//     z-index: 11001!important;
// }

.modal-backdrop.show + .select2-container {
    z-index: 99999;
}

.cke_dialog_background_cover + .cke_dialog_container {
    z-index: 99999!important;
}

.ui-menu.ui-widget.ui-widget-content.ui-autocomplete.ui-front {
    z-index: 99999!important;
}

.form-control:disabled, .form-control[readonly] {
    background-color: transparent!important;
    opacity: 1;
    border: none!important;
}

select[readonly] {
    &.select2- {
        &single, &action, &removable, &opportunites, &os, &contrat, &op, &anciens-contrats, &contrats {
            + .select2-container {
                pointer-events: none;
                touch-action: none;

                .select2-selection {
                    background: transparent !important;
                    border: none !important;
                    box-shadow: none;
                }

                .select2-selection__arrow,
                .select2-selection__clear {

                }
            }
        }
    }
}

.copyclipboard {
    position: relative;
    &::before {
        content: "\f0c5";
        font-family: "FA Regular";
        position: absolute;
        left: -15px;
        top: 50%;
        transform: translateY(-50%);
    }
}

textarea.form-control
{
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
      
    &::-webkit-scrollbar-thumb {
        background: #364261;
        border-radius: 10px;
    }
      
    &::-webkit-scrollbar-thumb:hover {
        background: ligthen(#364261, 5%);
        cursor: pointer;
    }
}

.bold-link {
    font-weight: 700;
}

table.dataTable {
    width: 100%!important;
    border: 0!important;
    thead .sorting::after {
        content: "\f3d0";
        top: 50%!important;
    }
}

.select2-container--default .select2-results__option[aria-disabled=true][data-select2-id^="select2-data-select2-type1"],
.select2-container--default .select2-results__option[aria-disabled=true][data-select2-id^="select2-data-select2-type2"],
.select2-container--default .select2-results__option[aria-disabled=true][data-select2-id^="select2-data-select2-action_actTypePrmId"],
.select2-container--default .select2-results__option[aria-disabled=true][data-select2-id^="select2-data-select2-action_actType2PrmId"],
.select2-container--default .select2-results__option[aria-disabled=true][data-select2-id^="select2-data-select2-get-option"]
{
    display: none;
}

.fake-search-button {
    color: #fff;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #4d5875;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e1e5ef;
    border-left: 0;
    height: 40px;
    span {
        vertical-align: middle;
    }
}

.form-control:focus + .fake-search .fake-search-button {
    color: #4d5875;
    background-color: #fff;
    border-color: #b3c6ff;
    outline: 0;
    box-shadow: none;
}

.table-responsive {
    &::-webkit-scrollbar:horizontal {
        height: 14px;
    }
}

#form_client {
    table {
        * {
            font-size: 12px;
        }
        .form-control:not(textarea) {
            height: 30px!important;
        }
        .select2-container {
            height: 30px !important;
            .select2-selection--single {
                height: 30px !important;
                .select2-selection__clear {
                    margin-top: -4px;
                }
                .select2-selection__rendered {
                    line-height: 18px;
                }
                .select2-selection__arrow {
                    height: 20px;
                }
            }
        }
    }
}

.main-header {
    .main-header-center {
        margin: 0;
        @media (min-width: 992px) {
            margin: 0 50px;
        }
    }
    .main-header-left .form-control {
        width: 195px;
        @media (min-width: 992px) {
            width: 300px;
        }
        @media (min-width: 1200px) {
            width: 350px;
        }
    }
    .main-header-right {
        .select2-container--default .select2-selection--single {
            border: none!important;
        }
    }
}

#select2-select-country-flag-results {
    .select2-results__option {
        & > div {
            display: flex;
            justify-content: start;
            align-items: center;
        }
    }
}

.image {
    min-height: auto!important;
}

.fc-event-title {
    white-space: normal;
}


@media (max-width: 992px) {
    .fc .fc-daygrid-body-balanced .fc-daygrid-day-events {
        position: relative!important;
    }
    .fc .fc-toolbar {
        flex-direction: column;
        gap: 15px;
    }
}

#onglet-parc {
    table {
        tr {
            td {
                .vich-file {
                    display: flex;
                    & > div {
                        display: flex;
                        flex-direction: row-reverse;
                        align-items: center;
                        label {
                            margin-bottom: 0;
                            white-space: nowrap;
                            margin-left: 3px;
                        }
                    }
                }
                img {
                    margin-right: 12px;
                }
            }
        }
    }
}

.ck.ck-content.ck-editor__editable {
    min-height: 200px!important;
}

.statistiques_corridor, .statistiques_corridor_details {
    .stats {
        display: grid;
        margin-top: 20px;
        grid-template-columns: repeat(1, 1fr);
        @media (min-width: 574px)
        {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (min-width: 992px)
        {
            grid-template-columns: repeat(3, 1fr);
        }
        @media (min-width: 1200px)
        {
            grid-template-columns: repeat(4, 1fr);
        }
        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-width: 150px;
            background: #03418f;
            border: solid 10px lighten(#03418f, 10%);
            color: white;
            border-radius: 50%;
            min-height: 150px;
            margin: 10px auto;
            @media (min-width: 992px)
            {
                min-height: 200px;
                min-width: 200px;
            }
        }
    }
}

.contacts {
    table{
        width: inherit;
    }
}

blockquote {
    margin: 0;
    p {
        padding: 15px;
        background: #eee;
        border-radius: 5px;
        &::before {
            content: '\201C';
        }
        &::after {
            content: '\201D';
        }
    }
}

.app-sidebar .slide.is-expanded.is-always-expanded {
    .side-menu__label {
        color: inherit!important;
    }
    .side-menu__icon {
        fill: none!important;
        color: inherit!important;
    }
}

.ep_contrat_details {
    .copyclipboard {
        position: relative;
        display: block;
        &::before {
            content: "\f0c5";
            font-family: "FA Regular";
            position: absolute;
            left: -25px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
.hidden{
    display: none !important;
}

.page .border-radius-15 {
    border-radius: 1.5em;
}

.text-bold {
    font-weight: 700;
}

.font-size-xl {
    font-size: x-large;
}

.text-end {
    text-align: end;
}

.icon {
    height: 1.2em;
}

.logo-index {
    height: 8em;
    margin-bottom: 2em;
}

.header-icon.primary {
    color: #03418f;
    &:hover {
        color: #045cd6;
    }
}

.carousel-inner {
    height: 0;
    padding-bottom: 75%;
}

.carousel-item {
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.carousel-item img {
    height: 100%;
    object-fit: cover;
}

[autocomplete=one-time-code] {
     --otp-digits: 6;
     --otp-ls: 2ch;
     --otp-gap: 1.25;
     --_otp-bgsz: calc(var(--otp-ls) + 1ch);
     --_otp-digit: 0;

     all: unset;
     background:
             linear-gradient(90deg, var(--otp-bg, #EEE) calc(var(--otp-gap) * var(--otp-ls)), transparent 0),
             linear-gradient(90deg, var(--otp-bg, #EEE) calc(var(--otp-gap) * var(--otp-ls)), transparent 0);
     background-position: calc(var(--_otp-digit) * var(--_otp-bgsz)) 0, 0 0;
     background-repeat: no-repeat, repeat-x;
     background-size: var(--_otp-bgsz) 100%;
     caret-color: var(--otp-cc, #222);
     caret-shape: block;
     clip-path: inset(0% calc(var(--otp-ls) / 2) 0% 0%);
     font-family: ui-monospace, monospace;
     font-size: var(--otp-fz, 2.5em);
     inline-size: calc(var(--otp-digits) * var(--_otp-bgsz));
     letter-spacing: var(--otp-ls);
     padding-block: var(--otp-pb, 1ch);
     padding-inline-start: calc(((var(--otp-ls) - 1ch) / 2) * var(--otp-gap));
}
